import React from "react";
import "./homepage.css";
import { Link } from "react-router-dom";
import Footer from "../component/Footer";
import Header from "../component/Header";

function Homepage() {
  return (
    <>
      <Header />

      {/* Empowering Paragraph */}
      <div className="home_hero">
        {/* Div 1 */}
        <div id="img">
          <h1 className="hover-sentence">
            <strong>
              <span className="hover-word">Empowering&nbsp; </span>
            </strong>
            <strong>
              <span className="hover-word"> your&nbsp;</span>
            </strong>
            <strong>
              <span className="hover-word">business&nbsp;</span>
            </strong>
            <strong>
              <span className="hover-word">through&nbsp;</span>
            </strong>
            <strong>
              <span className="hover-word">solutions&nbsp;</span>
            </strong>
            <strong>
              <span className="hover-word">tailored&nbsp;</span>
            </strong>
            <strong>
              <span className="hover-word">just&nbsp;</span>
            </strong>
            <strong>
              <span className="hover-word">for &nbsp;</span>
            </strong>
            <strong>
              <span className="hover-word">you&nbsp;</span>
            </strong>
          </h1>

          <p id="digitech">
            At Presume DigiTech, we offer a comprehensive suite of services
            tailored that are customized to meet the unique needs of each client.
          </p>
          <Link to="/services">
            {" "}
            <button id="get">Get Started Today</button>
          </Link>
          
        </div>


        {/* Div 2 */}
        {/* <div id="homeimage">
          <img src="/images/homepagenew.png"/>
         
        </div> */}
      </div>

        {/* Innovation Section */}
        <div id="inovation">
          <p>Experienced, Supportive, Professional</p>
          <hr />
          <p className="hover-sentence2">
              <span className="hover-word1">Where </span>
              <span className="hover-word1">innovation </span>
              <span className="hover-word1">meets </span>
            <br />
              <span className="hover-word1">dedication </span>
              <span className="hover-word1">and </span>
              <span className="hover-word1">fervent </span>
            <br />
              <span className="hover-word1">
           expertise
              </span>
            </p>
        </div>


      {/* Achieve Goals Section */}
      <section id="achieve">
        <h2>
          <strong>Achieve Your Goals with Strategy</strong>
        </h2>
        <p>
          Presume DigiTech is a cutting-edge firm specializing in Information
          Technology (IT) solutions and Digital Marketing services. Our mission
          is to empower businesses by providing innovative digital solutions
          that enhance operational efficiency and drive growth.
        </p>
      </section>

      {/* Services Cards Section */}
      <div className="card-container">
        <div className="card">
          <section className="card-content">
            <h3>Digital Marketing</h3>
            <p>
              Unlock Your Brand's Potential with Cutting-Edge Digital Marketing
              Solutions
            </p>
            <Link to="/digital">
              <button>Read More</button>
            </Link>
          </section>
          <div className="card-image1"></div>
        </div>
        <div className="card">
          <section className="card-content">
            <h3>Website Development</h3>
            <p>
              Crafting Stunning Websites that Elevate Your Online Presence and
              Drive Results
            </p>
            <Link to="/website">
              <button>Read More</button>
            </Link>
          </section>
          <div className="card-image2"></div>
        </div>
        <div className="card">
          <section className="card-content">
            <h3>Mobile App Development</h3>
            <p>
              We provide seamless UX and bring your ideas to life on iOS and
              Android platforms
            </p>
            <Link to="/mobile">
              <button>Read More</button>
            </Link>
          </section>
          <div className="card-image3"></div>
        </div>
        <div className="card">
          <section className="card-content">
            <h3>AI & ML</h3>
            <p>
              Empowering Enterprises with a plethora of Advanced AI Solutions
            </p>
            <Link to="/aiml">
              <button>Read More</button>
            </Link>
          </section>
          <div className="card-image4"></div>
        </div>
        <div className="card">
          <section className="card-content">
            <h3>Cyber Security Solutions</h3>
            <p>Fortifying Your Digital Defenses, One Byte at a Time</p>
            <Link to="/cyber">
              <button>Read More</button>
            </Link>
          </section>
          <div className="card-image5"></div>
        </div>
        <div className="card">
          <section className="card-content">
            <h3>Testing</h3>
            <p>
              We ensure that your applications and systems perform flawlessly
            </p>
            <Link to="/testing">
              <button>Read More</button>
            </Link>
          </section>
          <div className="card-image6"></div>
        </div>
      </div>

      {/* Tied Section */}
      <div id="tie">
        <h2 className="brand_section_title">
          <strong>Trusted By</strong>
        </h2>
        <div className="brand-card">
          <div id="overseas">
            <img
              src="/images/presume-overseas.png"
              alt="Presume Overseas Education Pvt. Ltd."
            />
            <h2>Presume Overseas </h2>
            <h3>Education (opc) PVT.LTD</h3>
          </div>

          <div id="engine">
            <img src="/images/cropped-logoed_W-1-2.avif" alt="Engine Detox" />
            <h2>Engine Detox</h2>
          </div>

          <div id="trust">
            <img
              src="/images/Frame 3.avif"
              alt="Barna International India LTD."
            />
            <h3>Barna International India LTD.</h3>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <Footer />
    </>
  );
}

export default Homepage;
